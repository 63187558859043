import React, { useEffect, useContext } from 'react'
import Layout from '../../components/Layout'
import { navigate } from 'gatsby'
import route from '../../utils/route'
import * as UserActions from '../../context/actions/userActions'
import AppContext from '../../context/context'
import SpinnerWithText from '../../components/spinnerWithText/SpinnerWithText'
import { t } from '../../i18n'
// import { setToken } from '../../api/api'

const Operador = ({ location }) => {
  const { dispatch } = useContext(AppContext)
  const token = location.search.split('?t=')[1]
  useEffect(() => {
    if (token) {
      dispatch(UserActions.impersonateUser(token))
    } else if (token === '') {
      dispatch(UserActions.logout())
    }
    navigate(route('index'))
  }, [token])
  return (
    <Layout selector={false} seoData={null}>
      <SpinnerWithText text={t('login.cargando')} />
    </Layout>
  )
}

export default Operador
